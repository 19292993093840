<template>
  <base-table-row
    class="tariffs-table-item"
    :columns="columns"
    v-bind="item"
    col-class="tariffs-table-item__col"
  >
    <template #server>
      <router-link
        :to="{ name: 'saasDetail', params: { id: item.id } }"
        class="tariffs-table-item__link medium-text"
      >
        {{ item.name }}
      </router-link>
    </template>
    <template #id>
      <div class="tariffs-table-item__ip note-color">
        {{ item.id }}
      </div>
    </template>
    <template #expiredate>
      <div v-if="!item.isDeployMe" class="tariffs-table-item__ip-promise">
        <plain-button
          v-if="item?.promise !== 'null'"
          v-tooltip="{
            content: promiseOnItem,
            autoHide: false,
            placement: 'top-start',
            container: false,
          }"
          icon="promise-icon"
          class="allowed-user-rights__promise"
        />
        <div class="tariffs-table-item__ip note-color">
          {{ $d(item?.expiredate, 'short') }}
        </div>
      </div>
      <div v-else class="tariffs-table-item__ip-promise"></div>
      <plain-button
        v-if="hasProlongTool"
        color="primary"
        @click="costAutoProlong === '0.00' ? singleProlongFree(item) : singleProlong(item)"
      >
        {{ $t('actions.prolong') }}
      </plain-button>
    </template>
    <!--    <template #period>-->
    <!--      <div class="tariffs-table-item__ip note-color">-->
    <!--        {{ $tc('period.month_c', item.period) }}-->
    <!--      </div>-->
    <!--    </template>-->
    <template #autoprolong>
      <div v-if="!item.isDeployMe">
        <div class="tariffs-table-item__icon">
          <saas-autoprolong
            v-tooltip="{
              content: isAutoProlong,
              autoHide: false,
              placement: 'left-start',
            }"
            :tariff="item"
          />
        </div>
        <div class="tariffs-table-item__icon">
          {{ autoprolongLabel }}
        </div>
      </div>
      <div v-else>
        <tumbler
          v-tooltip="tooltip"
          :value="item.actiovationflag"
          class="theme-switcher__tumbler"
          @change="setActivationFlag"
        />
        <main-label
          v-tooltip="tooltip"
          :color="item.actiovationflag === true ? 'success' : 'error'"
          theme="plain"
          class="tariffs-table-item__status"
        >
          {{ item.actiovationflag === true ? $t('on') : $t('off') }}
        </main-label>
      </div>
    </template>
    <template #cost>
      <div class="tariffs-table-item__cost note-color">
        {{ $n(costAutoProlong, 'currency', $i18n.locale) }}
      </div>
    </template>
    <template #status>
      <main-label :color="item.status.color" theme="plain" class="tariffs-table-item__status">
        {{ $t(`status.${item.status.key}`) }}
      </main-label>
    </template>
    <template #more>
      <v-popover
        v-click-outside="closePopoverOnOutside"
        placement="left"
        :open="isPopoverOpen"
        trigger="manual"
        popover-class="tariff-table-item__popover"
      >
        <plain-button
          icon="more"
          class="tariffs-table-item__more"
          @click="togglePopoverState"
        /><template #popover>
          <saas-context-menu :tariff="item" class="tariff-table-item__context-menu"
        /></template>
      </v-popover>
    </template>
  </base-table-row>
</template>

<script>
import BaseTableRow from '@/components/Table/BaseTableRow';
import MainLabel from '@/components/Labels/MainLabel.vue';
import SaasContextMenu from '../../../components/SaasContextMenu';
import SaasAutoprolong from '../../../components/SaasAutoprolong';
import SaasProlongSingle from '../../../components/SaasProlongSingle.vue';
import prolongSingle from '@/mixins/billmgr/prolongSingle';
import prolongSingleFree from '@/mixins/billmgr/prolongSingleFree';
import popoverFix from '@/mixins/popoverFix';
import SaasProlongSinglePayments from '@/layouts/Saas/components/SaasProlongSinglePayments';
import prolongText from '@/mixins/prolongText';
import Tumbler from '@/components/BaseTumbler/Tumbler.vue';
export default {
  name: 'TariffsTableRow',
  components: { Tumbler, BaseTableRow, MainLabel, SaasContextMenu, SaasAutoprolong },
  mixins: [prolongSingle, prolongSingleFree, popoverFix, prolongText],
  props: {
    columns: {
      type: Array,
      required: true,
    },
    item: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      costAutoProlong: 0,
      moduleMain: this.item.isDeployMe ? 'moduleStack' : 'moduleSaas',
      singleProlongComponentFirst: SaasProlongSingle,
      singleProlongComponent: SaasProlongSinglePayments,
    };
  },
  computed: {
    autoprolongLabel() {
      const autoProlong = this.item.autoprolong_name;
      return autoProlong.length > 0 ? `${autoProlong}` : 'нет';
    },
    tooltip() {
      return {
        content: this.$t('actiovationflag.hint'),
        placement: 'right-start',
        container: 'body',
      };
    },
    isAutoProlong() {
      return this.item.autoprolong_period === 'null'
        ? 'Включить автопродление'
        : 'Автопродление включено';
    },
    tools() {
      if (this.item.isDeployMe) return this.$store.state.moduleSaas.toolsDeployMe;
      else return this.$store.state.moduleSaas.toolsGitFlic;
    },
    cost() {
      return this.item.cost;
    },
    hasProlongTool() {
      return (
        this.item.prolongAvailable &&
        !!this.tools &&
        !!this.tools.prolong &&
        this.tools.prolong.isEnable(this.item)
      );
    },
  },
  mounted() {
    if (this.hasProlongTool) this.getCostTable();
  },
  methods: {
    setActivationFlag(value) {
      const params = {
        actiovationflag: value === true ? 'on' : 'off',
        elid: this.item.id,
      };
      this.$store
        .dispatch('moduleStack/updateTariff', params)
        .then(async () => {
          // this.showSuccessModal(this.$t('actiovationflag.success'));
          await this.$store.dispatch('moduleSaas/fetchList');
          // this.fetchList();
        })
        .catch(e => {
          this.showError(e);
        });
    },
  },
};
</script>

<i18n>
{
  "ru": {
    "activeUnder": "До {date} г.",
    "mb": "{num} МБ",
    "gb": "{num} ГБ",
    "actions": {
      "prolong": "Продлить"
    },
    "actiovationflag": {
      "text": "Автовключение услуги",
      "success": "Изменение прошло успешно",
      "hint": "Автоматическое включение услуги при положительном балансе"
    },
    "on": "Включено",
    "off": "Выключено",
    "promise" : "Для услуги активирован обещанный платеж до {promise}",
    "prolong": {
      "title": "Продление тарифа",
      "error": "К сожалению, что-то пошло не так",
      "success_basket": "Продление тарифа успешно добавлено в корзину",
      "success_order": "Продление тарифа прошло успешно. Данные скоро обновятся.",
      "free": "Активировать",
      "cancel": "Отмена",
      "confirm": "Оплатить",
      "order": "В корзину"
    }
  }
}
</i18n>

<style lang="stylus" scoped>
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';
.allowed-user-rights{
  &__promise{
    margin-right: 1px;
  }
}
.promise{
  display: inline-block;
  vertical-align: middle !important;
  margin-right: 0.5rem;
}
.tariffs-table-item {
  &__ip{
    &-promise{
      display: flex;
      flex-flow: row nowrap;
      justify-content: flex-start;
      align-items: center;
    }
    &-prolong{
      display: block;
    }
  }
  font-size: 1rem;
  >>> &__col {
    vertical-align: middle;

    +breakpoint(md-and-down) {
      min-width: min-content;
      max-width: unset!important;
    }

    +breakpoint(ml-and-up) {
      text-overflow: ellipsis;
      overflow: hidden;
      position: relative;
      z-index: 1;
    }

    &:hover {
      background: var(--main-card-bg);
      text-overflow: unset;
      overflow: visible;
      z-index: 2;
    }
  }
  &__icon{
    display: inline-block;
    vertical-align: middle !important;
    margin-right: 0.5rem;
  }
 &__link {
    position: relative;
    padding: 0.5em;
    margin: -0.5em;
    color: $primary-color;
    text-decoration: none;
    white-space: nowrap;
    background: inherit;
    &:hover {
      color: darken($primary-color, 20%);
    }

    &:hover::after {
      +breakpoint(ml-and-up) {
        content: '';
        display: block;
        size(2.5em, 100%);
        absolute(0px, 100%);
        background: linear-gradient(to right, var(--main-card-bg) 0%, transparent 100%);
      }
    }
  }
}
</style>

<style lang="stylus">
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';
.tariff-table-item {
  &__popover {
    text-align: left
    &.tooltip[x-placement^="left"] {
      +breakpoint(ms-and-down) {
        margin-right: 0;
      }
    }
    &.tooltip[x-placement^="right"] {
      +breakpoint(ms-and-down) {
        margin-left: 0;
      }
    }
    .popover-arrow {
      +breakpoint(ms-and-down) {
        display: none;
      }
    }
  }

  &__context-menu {
    +breakpoint(ms-and-down) {
      max-width: 100%;
      width: calc(100vw - 6rem);
    }
  }
}
</style>
