var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"saas"},[_c('div',{staticClass:"saas-info"},[_c('div',{staticClass:"saas-info__content"},[_c('page-title',{staticClass:"saas-info__title"},[_vm._v(" "+_vm._s(_vm.$t('title'))+" ")]),(_vm.list.length)?_c('div',{staticClass:"saas-mode"},[_c('plain-button',{staticClass:"saas-mode__btn",attrs:{"icon":"grid","watch-active":"","active":_vm.mode === 'grid'},on:{"click":function($event){return _vm.setMode('grid')}}}),_c('plain-button',{staticClass:"saas-mode__btn",attrs:{"icon":"list","watch-active":"","active":_vm.mode === 'table'},on:{"click":function($event){return _vm.setMode('table')}}})],1):_vm._e()],1),(!_vm.isAstra)?_c('div',{staticClass:"saas-info__create",class:{ visible: _vm.list.length }},[_c('base-button',{attrs:{"to":{ name: 'saasOrder' }}},[_vm._v(" "+_vm._s(_vm.$t('newServer'))+" ")])],1):_vm._e()]),_c('transition',{attrs:{"name":"fade","mode":"out-in","appear":""}},[(_vm.isLoading)?_c('div',{staticClass:"saas-info__loader"},[_c('base-loader',{staticClass:"saas-info__loader-item"})],1):_c('div',{staticClass:"saas-content"},[(_vm.isServerError)?_c('base-alert',{staticClass:"saas-info__alert",attrs:{"title":_vm.$t('errors.serverError.title'),"icon":"warn"}},[_vm._v(" "+_vm._s(_vm.$t('errors.serverError.text'))+" ")]):(!_vm.list.length && !_vm.isServerError)?_c('base-empty',{staticClass:"saas-info__empty",attrs:{"title":_vm.$t('empty.title'),"text":_vm.$t('empty.text'),"link":{
          title: _vm.$t('createServer'),
          to: { name: 'saasOrder' },
          disabled: !_vm.hideButtonCreate,
        },"stacklink1":_vm.link1
            ? {
                title: _vm.$t('stacklink1.title'),
                to: { name: 'BillingPayers' },
                text: _vm.$t('stacklink1.text'),
              }
            : null,"stacklink2":_vm.link2
            ? {
                title: _vm.$t('stacklink2.title'),
                to: { name: 'ProfilePersonal' },
                text: _vm.$t('stacklink2.text'),
              }
            : null}}):_c('div',{staticClass:"saas-content__list"},[_c('transition',{attrs:{"name":"fade","mode":"out-in"}},[_c(_vm.modeComponents[_vm.mode],{tag:"component"})],1)],1)],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }